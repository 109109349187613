const {
  commonPageData
} = require('./commonData.js');

const guideArticleText = (WpTag = '') => `
  ${commonPageData(WpTag)}
  GuideArticleText {
    guideArticleTextSources
    guideArticleTextAuthors {
      guideArticleTextAuthorsAuthor
    }
  }
  content
  date
  dateGmt
  featuredImage {
    node {
      altText
      sourceUrl
      srcSet
    }
  }
  id
  slug
  tags {
    nodes {
      name
      slug
      uri
    }
  }
  title
  excerpt
  uri
  featuredImage {
    node {
      altText
      srcSet
      sourceUrl
    }
  }
  categories {
    nodes {
      name
      slug
      uri
    }
  }
`;

module.exports.guideArticleText = guideArticleText;
