import React from "react";

import Post from "@/templates/guideArticleText";

const GuideArticleTextPreview = (props) =>
  <Post {...props}
    type="guideArticleText"
  />;

export default GuideArticleTextPreview;
