import React from 'react';

import Loader from 'components/Loader';
import Article from 'templates/Article';

import withPreview from 'hoc/withPreview';

import {
  guideArticleText
} from '@/constans/graphql/guideArticleText';

const Post = ({
  pageContext,
  loaded
}) => {
  if (!loaded) return <Loader />;

  return <Article post={pageContext.guideArticleText} />;
};

const queryPreview = `
query GuideArticleTextQuery($id: ID = "") {
  guideArticleText(id: $id, idType: DATABASE_ID, asPreview: true) {
    ${guideArticleText()}
  }
}
`;

export default withPreview(Post, {
  queryPreview
});
